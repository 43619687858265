import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Typography } from '@design-system/typography';

import Container from 'layout/Container';
import { Layout } from 'layout/Layout';
import { getBuildProcessInfo } from 'utils/buildProcess';
import withContentstackPreview from 'utils/livePreview';

import { TLDSPageData } from './models';

import './LDSPage.scss';

/**
 * Private & public template
 */
export const LDSPage: FC<TLDSPageData> = ({
  data: {
    pageData,
    footer,
    header,
    headerAccount,
    headerNavigationLoggedIn,
    footerNavigationLoggedIn,
  },
  pageContext,
}) => {
  getBuildProcessInfo(pageContext);
  const { seo, lds_block } = pageData;

  return (
    <Layout
      seo={seo}
      pageUid={pageContext.uid}
      footer={footer}
      footerNavigationLoggedIn={footerNavigationLoggedIn}
      header={header}
      headerNavigationLoggedIn={headerNavigationLoggedIn}
      headerAccount={headerAccount}
      className="lds-page"
      privatePage={false}
      data-testid="LDSPage"
    >
      <Container>
        <Typography variant="rte" content={lds_block[0].fields.ldsContent} />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String, $locale: String) {
    pageData: contentstackFinishLdsTemplate(id: { eq: $id }) {
      seo {
        ...SeoFragment
      }
      lds_block {
        fields {
          ldsContent
        }
      }
    }
    footer: contentstackFinishFooter(publish_details: { locale: { eq: $locale } }) {
      ...FooterFragment
    }
    header: contentstackFinishHeader(publish_details: { locale: { eq: $locale } }) {
      ...HeaderFragment
    }
    headerAccount: contentstackFinishHeaderAccount(publish_details: { locale: { eq: $locale } }) {
      ...HeaderAccountFragment
    }
    headerNavigationLoggedIn: contentstackFinishNavigationLoggedIn(
      publish_details: { locale: { eq: $locale } }
    ) {
      ...HeaderNavigationLoggedInFragment
    }
    footerNavigationLoggedIn: contentstackFinishFooterNavigationLoggedIn(
      publish_details: { locale: { eq: $locale } }
    ) {
      ...FooterNavigationLoggedInFragment
    }
    redirects: contentstackFinishGuard(publish_details: { locale: { eq: $locale } }) {
      ...AuthGuardFragment
    }
  }
`;

export default withContentstackPreview(
  LDSPage,
  `${process.env.GATSBY_CONTENTSTACK_CONTENT_TYPES_PREFIX}_lds_template`
);
